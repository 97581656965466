// extracted by mini-css-extract-plugin
export var BottomToTop = "Header-module--BottomToTop--g9X++";
export var alertRed = "Header-module--alert-red--fn5RX";
export var animateWords = "Header-module--animateWords--hXwRO";
export var bgBarGrey = "Header-module--bg-bar-grey--n9UTZ";
export var bgBeige = "Header-module--bg-beige--OeAW2";
export var bgCopper = "Header-module--bg-copper--8Wb4d";
export var bgDaffodil = "Header-module--bg-daffodil--xj5qM";
export var bgGreyText = "Header-module--bg-grey-text--vJ7mg";
export var bgMing = "Header-module--bg-ming--DKZPi";
export var bgMint = "Header-module--bg-mint--fIOV7";
export var bgRed = "Header-module--bg-red--jMcIg";
export var bgRedTrans = "Header-module--bg-red-trans--46ZoU";
export var bgSand = "Header-module--bg-sand--q0IaJ";
export var bgSoftSun = "Header-module--bg-softSun--51ZSz";
export var bgTeal = "Header-module--bg-teal--w7eKL";
export var bgWhite = "Header-module--bg-white--V-80D";
export var bgWomenSharp = "Header-module--bg-women-sharp--M6GMk";
export var blackText = "Header-module--black-text--nSllG";
export var chinese = "Header-module--chinese--1jl8x";
export var copper = "Header-module--copper--TsddY";
export var corners = "Header-module--corners--O1fWv";
export var cursorNormal = "Header-module--cursor-normal--kr7rg";
export var cursorPointer = "Header-module--cursor-pointer--52tbA";
export var dUnset = "Header-module--d-unset--vBmz3";
export var darkGrey = "Header-module--dark-grey--Ef8u+";
export var dash = "Header-module--dash--SxMUG";
export var errorField = "Header-module--errorField--dSlHg";
export var flex = "Header-module--flex--CEi6T";
export var flex1 = "Header-module--flex-1--UBbhT";
export var fontSize12 = "Header-module--font-size-12--qA3Gp";
export var fontSize20 = "Header-module--font-size-20--6CKVL";
export var form = "Header-module--form--1jga2";
export var formWrapper = "Header-module--formWrapper--3o9xU";
export var fr = "Header-module--fr--jNdfK";
export var greyPlaceholder = "Header-module--grey-placeholder--KUbe7";
export var greyText = "Header-module--grey-text--syHQp";
export var h1sizing = "Header-module--h1sizing--hF+og";
export var h2sizing = "Header-module--h2sizing--jv6pA";
export var header = "Header-module--header--CKTky";
export var hidden = "Header-module--hidden--J7OIN";
export var image = "Header-module--image--zEKRE";
export var imageAni = "Header-module--imageAni--gM0AS";
export var imageZoom = "Header-module--imageZoom--G9-sJ";
export var imageZoomGatsby = "Header-module--imageZoomGatsby--ZBIIS";
export var italic = "Header-module--italic--KtstR";
export var label = "Header-module--label--jWG0T";
export var lightGrey = "Header-module--light-grey--i64q7";
export var logo = "Header-module--logo--Rm-80";
export var lora = "Header-module--lora--2Y8sr";
export var loraBold = "Header-module--lora-Bold--cRQmX";
export var loraBoldItalic = "Header-module--lora-BoldItalic--hZ8AC";
export var loraMedium = "Header-module--lora-Medium--X58he";
export var loraSemiBold = "Header-module--lora-SemiBold--Dl4Di";
export var main = "Header-module--main--C0e5U";
export var ming = "Header-module--ming--++7gU";
export var mingHover2 = "Header-module--ming-hover-2--hkf0X";
export var modalOpen = "Header-module--modal-open--kWgrR";
export var noUnderline = "Header-module--no-underline--f3UQY";
export var openSans = "Header-module--openSans--pT0Z4";
export var openSansBold = "Header-module--openSans-Bold--daNMy";
export var openSansSemiBold = "Header-module--openSans-SemiBold--IlhtD";
export var quotation = "Header-module--quotation--XH+Sq";
export var rightCopper = "Header-module--rightCopper--G0a3H";
export var rightLine = "Header-module--rightLine--yrAxU";
export var round = "Header-module--round--JIC0i";
export var roundCorners = "Header-module--round-corners--y+c+K";
export var row = "Header-module--row--tGbdE";
export var selectedType = "Header-module--selectedType--OIp3j";
export var shapes = "Header-module--shapes--OG7qF";
export var slogan = "Header-module--slogan--oLhVr";
export var softCorners = "Header-module--soft-corners--NY6D-";
export var softShadows = "Header-module--soft-shadows--NnDmK";
export var softerCorners = "Header-module--softer-corners--jRkQ9";
export var softyCorners = "Header-module--softy-corners--Gn8pH";
export var submitBtn = "Header-module--submitBtn--udSdk";
export var subtitle = "Header-module--subtitle--8a+8W";
export var tableShadow = "Header-module--table-shadow--EvNph";
export var teal = "Header-module--teal--PjBL4";
export var topNavShadow = "Header-module--top-nav-shadow--q696h";
export var type = "Header-module--type--0ZC5+";
export var uppercase = "Header-module--uppercase--yzhYE";
export var wFull = "Header-module--w-full--C8PwM";
export var white = "Header-module--white--VA6cK";
export var womenRed = "Header-module--women-red--lWU5Z";
export var womenSharp = "Header-module--women-sharp--G-8+r";
export var word = "Header-module--word--M+x6+";