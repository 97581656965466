import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import {
  formValidation,
  gaEventPush,
  getBackgroundImage,
  getFullPhoneCodes,
  getGatsbyImage,
  // getPhoneCodes,
} from "../../plugins/common"
import { CustomMarkdown } from "../../plugins/sanitizehtml"
import Button from "../Button"
import * as style from "./Header.module.scss"
import { preferredLang } from "../../enumerator/preferredLang"
import { createPipedrivePersonLead } from "../../DAO/eqtDAO"
import axios from "../../plugins/axios"
import Loading from "../Loading"
import DropdownV2 from "../quotation/DropdownV2"
import { pipedriveValue } from "../../enumerator/pipedriveValue"

const HomepageHeader = ({
  data,
  referee,
  referralData,
  lang,
  token,
  location,
}) => {
  const {
    title,
    subtitle,
    keywords,
    image,
    backgroundImage,
    emailLabel,
    formTitle,
    nameLabel,
    phoneLabel,
    submitLabel,
    productType,
    invalidFormMsg,
    thanksMsg,
    animateBeforeTitle,
  } = data

  const { heroTitle } = referee

  const defaultFormError = {
    firstName: false,
    familyName: false,
    phone: false,
    email: false,
  }
  const rightCopper = "../../images/articles/right-copper.webp"
  const rightLine = "../../images/articles/right-line.webp"

  const bg = getBackgroundImage(backgroundImage)
  const phoneCodeList = getFullPhoneCodes()
  const [quoteName, setQuoteName] = useState("")
  const [quoteEmail, setQuoteEmail] = useState("")
  const [quotePhone, setQuotePhone] = useState("")
  const [quoteCountryCode, setQuoteCountryCode] = useState("+852")
  const [quoteType, setQuoteType] = useState(productType[0])
  const [formError, setFormError] = useState(defaultFormError)
  const [errorMessage, setErrorMessage] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const gaEventLabels = {
    643: "home_hero_health_submit",
    644: "home_hero_life_submit",
    645: "home_hero_ci_submit",
    646: "home_hero_group_submit",
  }

  const submitQuote = async () => {
    const formData = {
      name: quoteName,
      email: quoteEmail,
      phone: quotePhone,
    }
    if (
      !formValidation(formData, setFormError, setErrorMessage, invalidFormMsg)
    ) {
      return
    }

    setLoading(true)
    try {
      const pipedriveEntry = {
        name: quoteName,
        email: quoteEmail,
        phone: quoteCountryCode + quotePhone,
        // source: [650, pipedriveValue.SOURCE_ALEA],
        source: referralData
          ? [650, parseInt(quoteType.value)]
          : parseInt(quoteType.value),
        // product: parseInt(quoteType.value),
        // url: location.href,
        // formPosition: pipedriveValue.POSITION_HEADER,
        preferedLanguage: preferredLang[lang],
        referral: referralData,
      }
      const pipedriveRes = await createPipedrivePersonLead(
        token,
        pipedriveEntry
      )
      // console.log("pipedriveRes = ", pipedriveRes)

      // //log
      const log = {
        title: quoteName + " from homepage",
        lang: lang,
        raw: pipedriveEntry,
      }
      await axios
        .strapi(token)
        .post("/get-a-quote-submissions", log)
        .catch(err => {
          throw err
        })

      gaEventPush(
        window,
        "event",
        "form submission",
        "lead",
        gaEventLabels[quoteType.value]
      )

      setSubmitted(true)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      className={`${style.header} bg-white`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      {loading ? (
        <div className={`d-relative`}>
          <Loading />
        </div>
      ) : (
        ""
      )}
      <div className="flex flex-lg-row flex-column">
        <div className="col-lg-6 col-12">
          {referralData ? (
            <h1 className={`ming me-2 ${style.slogan}`}>{heroTitle}</h1>
          ) : (
            <div className={``}>
              <div
                className={`flex position-relative ${style.slogan} 
          flex-column`}
              >
                <h1
                  className={`mb-0 ming me-2 ${
                    animateBeforeTitle
                      ? `order-1  ${style.chinese}`
                      : keywords && lang === "fr"
                      ? `order-0 ${style.fr}`
                      : "order-0"
                  }`}
                >
                  <CustomMarkdown
                    html={title}
                    color={`copper`}
                  ></CustomMarkdown>
                </h1>
                {keywords && (
                  <div
                    className={`${style.animateWords} h1sizing ${
                      animateBeforeTitle
                        ? `order-0 ${style.chinese}`
                        : lang === "fr"
                        ? `order-1 ${style.fr}`
                        : "order-1"
                    } `}
                  >
                    {keywords.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`${style.word}`}
                          style={{
                            animationDelay: 3 * index + "s",
                            animationDuration: 3 * keywords.length + "s",
                          }}
                        >
                          {item.value}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
              <div className={`copper ${style.subtitle} lora-Bold col-lg-6`}>
                {subtitle}
              </div>
            </div>
          )}
          <div
            className={`${style.shapes} ${
              lang === "zh-Hant" ? style.chinese : ""
            } position-relative`}
          >
            <GatsbyImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className={`${style.image}  position-absolute`}
              image={getGatsbyImage(image)}
              alt="alea"
            />
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className={`${style.rightCopper}   position-absolute`}
              src={rightCopper}
              alt="alea"
            />
            <StaticImage
              sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
              className={`${style.rightLine}   position-absolute`}
              src={rightLine}
              alt="alea"
            />
          </div>
        </div>
        <div
          className={`${
            image ? "col-lg-5" : ""
          } col-12 soft-shadows bg-white p-lg-4 p-4 softy-corners ${
            style.formWrapper
          }`}
        >
          {submitted ? (
            <h3 className={`teal text-start`}>{thanksMsg}</h3>
          ) : (
            <div className={`${style.form}`}>
              <div className={``}>
                <h2 className={`ming text-start`}>{formTitle}</h2>
              </div>
              <div className={` ${style.row} mb-4 align-items-center`}>
                <div className={`flex gap-2 flex-wrap flex-lg-nowrap`}>
                  {productType.map((type, index) => (
                    <div
                      key={index}
                      role="button"
                      className={`${style.type} ${
                        quoteType.value === type.value ? style.selectedType : ""
                      } corners py-1 px-2 text-center`}
                      onClick={() => setQuoteType(type)}
                      onKeyDown={() => setQuoteType(type)}
                      tabIndex={index}
                    >
                      {type.name}
                    </div>
                  ))}
                </div>
              </div>
              <div
                className={` ${
                  style.row
                } d-lg-flex d-block mb-4 align-items-center ${
                  formError.name ? style.errorField : ""
                }`}
              >
                <div className={`${style.label} col-lg-4 col-12 `}>
                  {nameLabel}
                </div>
                <input
                  type="text"
                  className={`corners border col-lg-8 col-12`}
                  value={quoteName}
                  onChange={e => setQuoteName(e.target.value)}
                />
              </div>
              <div
                className={`${style.row}  ${
                  formError.email ? style.errorField : ""
                } d-lg-flex d-block align-items-center mb-4`}
              >
                <div className={`${style.label} col-lg-4 col-12`}>
                  {emailLabel}
                </div>
                <input
                  type="email"
                  className={`corners border col-lg-8 col-12`}
                  value={quoteEmail}
                  onChange={e => setQuoteEmail(e.target.value)}
                />
              </div>
              <div
                className={`${style.row} ${
                  formError.phone ? style.errorField : ""
                } d-lg-flex d-block align-items-center col-lg-6 align-items-center`}
              >
                <div className={`${style.label}  col-lg-4 col-12`}>
                  {phoneLabel}
                </div>
                <div className={`col-lg-8 col-12 flex`}>
                  <div className={`col-3 px-0 position-relative`}>
                    <DropdownV2
                      options={phoneCodeList}
                      selected={quoteCountryCode}
                      setSelected={setQuoteCountryCode}
                      selectValue={true}
                      className={`bg-ming`}
                    />
                  </div>
                  <input
                    type="number"
                    className={`corners border col-9`}
                    value={quotePhone}
                    onChange={e => setQuotePhone(e.target.value)}
                  />
                </div>
              </div>
              {errorMessage && (
                <div className={`alert-red mt-2 openSans-Bold`}>
                  {errorMessage}
                </div>
              )}
              <div
                className={`${
                  errorMessage ? "mt-2" : "mt-4"
                } text-lg-end col-lg-12 text-center`}
              >
                <Button
                  buttonId={`homepage_hero_submit`}
                  className={style.submitBtn}
                  lang={lang}
                  type={`primary`}
                  text={submitLabel}
                  event={submitQuote}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HomepageHeader
