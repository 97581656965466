import React from "react"
import { CustomMarkdown } from "../../plugins/sanitizehtml"
import Img from "../Img"
import * as style from "./ComparisonTable.module.scss"
import tick from "../../images/comparison/tick.svg"
import cross from "../../images/comparison/cross.svg"

const ComparisonTable = ({ data }) => {
  const { title, subtitle, tableRow } = data

  const ComparisonRow = ({ row = {} }) => {
    const { title: rowTitle, tableCol: columns } = row
    const isHeader = col => col.logo || col.name
    const getBgColor = color =>
      color === "sand" ? "bg-beige" : color === "mint" ? "bg-mint" : "bg-white"
    return (
      <>
        <tr className={`openSans-SemiBold d-block d-lg-none mt-3 mb-1`}>
          {rowTitle}
        </tr>
        <tr className={`${style.row} flex align-text-center`}>
          <td className={`openSans-SemiBold d-lg-grid d-none`}>{rowTitle}</td>
          {columns &&
            columns.length &&
            columns.map((col, index) => (
              <td
                key={index}
                className={`flex-1 mx-1 ${
                  !isHeader(col) ? getBgColor(col.bgColor) : ""
                }`}
              >
                {col.logo && (
                  <Img image={col.logo} className={`${style.logo}`} />
                )}
                {col.name && <div className={`openSans-Bold`}>{col.name}</div>}
                {!isHeader(col) && (
                  <div className={``}>
                    <Img
                      image={col.tick ? tick : cross}
                      className={`${style.image}`}
                    />
                    <div className={`${style.label} `}>{col.label}</div>
                  </div>
                )}
              </td>
            ))}
        </tr>
      </>
    )
  }

  return (
    <div className={`${style.comparisonTable} text-center`}>
      {title && (
        <h2 className={`ming`}>
          <CustomMarkdown html={title} color={`copper`} />
        </h2>
      )}
      {subtitle && <CustomMarkdown html={subtitle} color={`copper`} />}
      {tableRow && tableRow.length && (
        <table className={`my-3`}>
          <tbody>
            {tableRow.map((row, i) => (
              <ComparisonRow key={i} row={row} />
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default ComparisonTable
